<template>

<header class="header noselect" style="text-align: center;">
  <n-h1 class="floating" disabled style="font-size: 100px;">🎓</n-h1>
  <n-h1 class="header__title" data-sal="slide-down" style="--sal-duration: 2s;">VPT E-vedlys</n-h1>
  <p class="header__subtitle" data-sal="slide-down" style="--sal-duration: 2s;">Viešųjų pirkimų mokymų sistema</p>
    <n-divider />
  <p class="header__subtitle" data-sal="slide-up" style="--sal-duration: 2s; max-width: 250px;">
    Sistemoje pateikiama su viešaisiais pirkimais susijusi mokymų medžiaga<br>
    <span><n-h1>📖</n-h1></span>
  </p>
  <br><br>
  <n-button text class="blob" @click="this.router.push({ path: '/docs' })" style="margin: auto;">
    <n-icon size="35" color="#fdb913">
      <ArrowForwardOutline />
    </n-icon>
  </n-button>
</header>

<section class="content">
  <div class="row">
    <div class="column">
      <svg class="shape yellow sal-animate" data-sal="slide-up" data-sal-delay="500" data-sal-duration="1000" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <rect width="60" height="60" rx="4" ry="4"></rect>
      </svg>
    </div>
    <div class="column">
      <svg class="shape pink sal-animate" data-sal="fade-in" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="68.57" height="65.75" viewBox="0 0 68.57 65.75">
        <path d="M37.52,1A5.88,5.88,0,0,0,31,1L2.23,21.91a5.88,5.88,0,0,0-2,6.16l11,33.88a5.88,5.88,0,0,0,5.24,3.8H52.1a5.88,5.88,0,0,0,5.24-3.8l11-33.88a5.88,5.88,0,0,0-2-6.16Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape pink sal-animate" data-sal="fade-in" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="68.57" height="65.75" viewBox="0 0 68.57 65.75">
        <path d="M37.52,1A5.88,5.88,0,0,0,31,1L2.23,21.91a5.88,5.88,0,0,0-2,6.16l11,33.88a5.88,5.88,0,0,0,5.24,3.8H52.1a5.88,5.88,0,0,0,5.24-3.8l11-33.88a5.88,5.88,0,0,0-2-6.16Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape violet sal-animate" data-sal="zoom-in" data-sal-delay="300" xmlns="http://www.w3.org/2000/svg" width="68.57" height="65.75" viewBox="0 0 68.57 65.75">
        <path d="M37.52,1A5.88,5.88,0,0,0,31,1L2.23,21.91a5.88,5.88,0,0,0-2,6.16l11,33.88a5.88,5.88,0,0,0,5.24,3.8H52.1a5.88,5.88,0,0,0,5.24-3.8l11-33.88a5.88,5.88,0,0,0-2-6.16Z"></path>
      </svg>
      <svg class="shape violet sal-animate" data-sal="zoom-in" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <path d="M38.94,2.34l9.52,17.41a4.49,4.49,0,0,0,1.79,1.79l17.41,9.52a4.49,4.49,0,0,1,0,7.89L50.25,48.47a4.49,4.49,0,0,0-1.79,1.79L38.94,67.66a4.49,4.49,0,0,1-7.89,0L21.53,50.25a4.49,4.49,0,0,0-1.79-1.79L2.34,38.94a4.49,4.49,0,0,1,0-7.89l17.41-9.52a4.49,4.49,0,0,0,1.79-1.79L31.06,2.34A4.49,4.49,0,0,1,38.94,2.34Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape violet sal-animate" data-sal="zoom-in" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <path d="M38.94,2.34l9.52,17.41a4.49,4.49,0,0,0,1.79,1.79l17.41,9.52a4.49,4.49,0,0,1,0,7.89L50.25,48.47a4.49,4.49,0,0,0-1.79,1.79L38.94,67.66a4.49,4.49,0,0,1-7.89,0L21.53,50.25a4.49,4.49,0,0,0-1.79-1.79L2.34,38.94a4.49,4.49,0,0,1,0-7.89l17.41-9.52a4.49,4.49,0,0,0,1.79-1.79L31.06,2.34A4.49,4.49,0,0,1,38.94,2.34Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape yellow sal-animate" data-sal="fade-in" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="68.57" height="65.75" viewBox="0 0 68.57 65.75">
        <path d="M37.52,1A5.88,5.88,0,0,0,31,1L2.23,21.91a5.88,5.88,0,0,0-2,6.16l11,33.88a5.88,5.88,0,0,0,5.24,3.8H52.1a5.88,5.88,0,0,0,5.24-3.8l11-33.88a5.88,5.88,0,0,0-2-6.16Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape green sal-animate" data-sal="slide-up" data-sal-delay="50" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <rect x="28" width="14" height="70" rx="7" ry="7"></rect>
        <rect x="28" width="14" height="70" rx="7" ry="7" transform="translate(0 70) rotate(-90)"></rect>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape green sal-animate" data-sal="slide-up" data-sal-delay="50" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <rect x="28" width="14" height="70" rx="7" ry="7"></rect>
        <rect x="28" width="14" height="70" rx="7" ry="7" transform="translate(0 70) rotate(-90)"></rect>
      </svg>
    </div>
    <div class="column">
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape blue sal-animate" data-sal="slide-up" data-sal-delay="50" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape violet sal-animate" data-sal="zoom-in" data-sal-delay="50" xmlns="http://www.w3.org/2000/svg" width="67.65" height="60.62" viewBox="0 0 67.65 60.62">
        <path d="M53.33,3.46a7.69,7.69,0,0,0-6-3.46h-27a7.69,7.69,0,0,0-6,3.46L.83,26.85a7.69,7.69,0,0,0,0,6.93l13.5,23.38a7.69,7.69,0,0,0,6,3.46h27a7.69,7.69,0,0,0,6-3.46l13.5-23.38a7.69,7.69,0,0,0,0-6.93Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape violet" data-sal="zoom-in" data-sal-delay="50" xmlns="http://www.w3.org/2000/svg" width="67.65" height="60.62" viewBox="0 0 67.65 60.62">
        <path d="M53.33,3.46a7.69,7.69,0,0,0-6-3.46h-27a7.69,7.69,0,0,0-6,3.46L.83,26.85a7.69,7.69,0,0,0,0,6.93l13.5,23.38a7.69,7.69,0,0,0,6,3.46h27a7.69,7.69,0,0,0,6-3.46l13.5-23.38a7.69,7.69,0,0,0,0-6.93Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape yellow" data-sal="slide-up" data-sal-delay="50" xmlns="http://www.w3.org/2000/svg" width="68.57" height="65.75" viewBox="0 0 68.57 65.75">
        <path d="M37.52,1A5.88,5.88,0,0,0,31,1L2.23,21.91a5.88,5.88,0,0,0-2,6.16l11,33.88a5.88,5.88,0,0,0,5.24,3.8H52.1a5.88,5.88,0,0,0,5.24-3.8l11-33.88a5.88,5.88,0,0,0-2-6.16Z"></path>
      </svg>
      <svg class="shape yellow" data-sal="fade-in" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="72.74" height="64.06" viewBox="0 0 72.74 64.06">
        <path d="M32.91,2a4,4,0,0,1,6.93,0L54,26.56l4,6.93L72.2,58.06a4,4,0,0,1-3.46,6H4a4,4,0,0,1-3.46-6L14.72,33.49l4-6.93Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape yellow" data-sal="slide-up" data-sal-delay="50" xmlns="http://www.w3.org/2000/svg" width="68.57" height="65.75" viewBox="0 0 68.57 65.75">
        <path d="M37.52,1A5.88,5.88,0,0,0,31,1L2.23,21.91a5.88,5.88,0,0,0-2,6.16l11,33.88a5.88,5.88,0,0,0,5.24,3.8H52.1a5.88,5.88,0,0,0,5.24-3.8l11-33.88a5.88,5.88,0,0,0-2-6.16Z"></path>
      </svg>
      <svg class="shape yellow" data-sal="fade-in" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="72.74" height="64.06" viewBox="0 0 72.74 64.06">
        <path d="M32.91,2a4,4,0,0,1,6.93,0L54,26.56l4,6.93L72.2,58.06a4,4,0,0,1-3.46,6H4a4,4,0,0,1-3.46-6L14.72,33.49l4-6.93Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape green" data-sal="slide-up" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <circle cx="30" cy="30" r="30"></circle>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape green" data-sal="slide-up" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <circle cx="30" cy="30" r="30"></circle>
      </svg>
    </div>
    <div class="column">
      <svg class="shape blue" data-sal="slide-up" data-sal-delay="50" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <rect x="28" width="14" height="70" rx="7" ry="7"></rect>
        <rect x="28" width="14" height="70" rx="7" ry="7" transform="translate(0 70) rotate(-90)"></rect>
      </svg>
      <svg class="shape pink" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <path d="M38.94,2.34l9.52,17.41a4.49,4.49,0,0,0,1.79,1.79l17.41,9.52a4.49,4.49,0,0,1,0,7.89L50.25,48.47a4.49,4.49,0,0,0-1.79,1.79L38.94,67.66a4.49,4.49,0,0,1-7.89,0L21.53,50.25a4.49,4.49,0,0,0-1.79-1.79L2.34,38.94a4.49,4.49,0,0,1,0-7.89l17.41-9.52a4.49,4.49,0,0,0,1.79-1.79L31.06,2.34A4.49,4.49,0,0,1,38.94,2.34Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape blue" data-sal="slide-up" data-sal-delay="50" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <rect x="28" width="14" height="70" rx="7" ry="7"></rect>
        <rect x="28" width="14" height="70" rx="7" ry="7" transform="translate(0 70) rotate(-90)"></rect>
      </svg>
      <svg class="shape pink" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <path d="M38.94,2.34l9.52,17.41a4.49,4.49,0,0,0,1.79,1.79l17.41,9.52a4.49,4.49,0,0,1,0,7.89L50.25,48.47a4.49,4.49,0,0,0-1.79,1.79L38.94,67.66a4.49,4.49,0,0,1-7.89,0L21.53,50.25a4.49,4.49,0,0,0-1.79-1.79L2.34,38.94a4.49,4.49,0,0,1,0-7.89l17.41-9.52a4.49,4.49,0,0,0,1.79-1.79L31.06,2.34A4.49,4.49,0,0,1,38.94,2.34Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape violet" data-sal="slide-up" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape violet" data-sal="slide-up" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape yellow" data-sal="fade-in" data-sal-delay="250" xmlns="http://www.w3.org/2000/svg" width="69.11" height="69.11" viewBox="0 0 69.11 69.11">
        <rect x="8.94" y="8.94" width="51.22" height="51.22" rx="4" ry="4" transform="translate(-14.31 34.55) rotate(-45)"></rect>
      </svg>
      <svg class="shape blue" data-sal="slide-up" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <rect width="60" height="60" rx="4" ry="4"></rect>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape yellow" data-sal="fade-in" data-sal-delay="250" xmlns="http://www.w3.org/2000/svg" width="69.11" height="69.11" viewBox="0 0 69.11 69.11">
        <rect x="8.94" y="8.94" width="51.22" height="51.22" rx="4" ry="4" transform="translate(-14.31 34.55) rotate(-45)"></rect>
      </svg>
      <svg class="shape blue" data-sal="slide-up" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <rect width="60" height="60" rx="4" ry="4"></rect>
      </svg>
    </div>
    <div class="column">
      <svg class="shape green" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <circle cx="30" cy="30" r="30"></circle>
      </svg>
      <svg class="shape green" data-sal="zoom-in" data-sal-delay="0" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape green" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <circle cx="30" cy="30" r="30"></circle>
      </svg>
      <svg class="shape green" data-sal="zoom-in" data-sal-delay="0" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape blue" data-sal="fade-in" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <rect width="60" height="60" rx="4" ry="4"></rect>
      </svg>
      <svg class="shape yellow" data-sal="fade-in" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <circle cx="30" cy="30" r="30"></circle>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape blue" data-sal="fade-in" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <rect width="60" height="60" rx="4" ry="4"></rect>
      </svg>
      <svg class="shape yellow" data-sal="fade-in" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <circle cx="30" cy="30" r="30"></circle>
      </svg>
    </div>
    <div class="column">
      <svg class="shape blue" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <rect x="28" width="14" height="70" rx="7" ry="7"></rect>
        <rect x="28" width="14" height="70" rx="7" ry="7" transform="translate(0 70) rotate(-90)"></rect>
      </svg>
      <svg class="shape violet" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <circle cx="30" cy="30" r="30"></circle>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape blue" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <rect x="28" width="14" height="70" rx="7" ry="7"></rect>
        <rect x="28" width="14" height="70" rx="7" ry="7" transform="translate(0 70) rotate(-90)"></rect>
      </svg>
      <svg class="shape violet" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <circle cx="30" cy="30" r="30"></circle>
      </svg>
    </div>
    <div class="column">
      <svg class="shape pink" data-sal="fade-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <path d="M38.94,2.34l9.52,17.41a4.49,4.49,0,0,0,1.79,1.79l17.41,9.52a4.49,4.49,0,0,1,0,7.89L50.25,48.47a4.49,4.49,0,0,0-1.79,1.79L38.94,67.66a4.49,4.49,0,0,1-7.89,0L21.53,50.25a4.49,4.49,0,0,0-1.79-1.79L2.34,38.94a4.49,4.49,0,0,1,0-7.89l17.41-9.52a4.49,4.49,0,0,0,1.79-1.79L31.06,2.34A4.49,4.49,0,0,1,38.94,2.34Z"></path>
      </svg>
      <svg class="shape violet" data-sal="fade-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape pink" data-sal="fade-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <path d="M38.94,2.34l9.52,17.41a4.49,4.49,0,0,0,1.79,1.79l17.41,9.52a4.49,4.49,0,0,1,0,7.89L50.25,48.47a4.49,4.49,0,0,0-1.79,1.79L38.94,67.66a4.49,4.49,0,0,1-7.89,0L21.53,50.25a4.49,4.49,0,0,0-1.79-1.79L2.34,38.94a4.49,4.49,0,0,1,0-7.89l17.41-9.52a4.49,4.49,0,0,0,1.79-1.79L31.06,2.34A4.49,4.49,0,0,1,38.94,2.34Z"></path>
      </svg>
      <svg class="shape violet" data-sal="fade-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape green" data-sal="slide-up" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="72.74" height="64.06" viewBox="0 0 72.74 64.06">
        <path d="M32.91,2a4,4,0,0,1,6.93,0L54,26.56l4,6.93L72.2,58.06a4,4,0,0,1-3.46,6H4a4,4,0,0,1-3.46-6L14.72,33.49l4-6.93Z"></path>
      </svg>
      <svg class="shape blue" data-sal="zoom-in" data-sal-delay="300" xmlns="http://www.w3.org/2000/svg" width="67.65" height="60.62" viewBox="0 0 67.65 60.62">
        <path d="M53.33,3.46a7.69,7.69,0,0,0-6-3.46h-27a7.69,7.69,0,0,0-6,3.46L.83,26.85a7.69,7.69,0,0,0,0,6.93l13.5,23.38a7.69,7.69,0,0,0,6,3.46h27a7.69,7.69,0,0,0,6-3.46l13.5-23.38a7.69,7.69,0,0,0,0-6.93Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape green" data-sal="slide-up" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="72.74" height="64.06" viewBox="0 0 72.74 64.06">
        <path d="M32.91,2a4,4,0,0,1,6.93,0L54,26.56l4,6.93L72.2,58.06a4,4,0,0,1-3.46,6H4a4,4,0,0,1-3.46-6L14.72,33.49l4-6.93Z"></path>
      </svg>
      <svg class="shape blue" data-sal="zoom-in" data-sal-delay="300" xmlns="http://www.w3.org/2000/svg" width="67.65" height="60.62" viewBox="0 0 67.65 60.62">
        <path d="M53.33,3.46a7.69,7.69,0,0,0-6-3.46h-27a7.69,7.69,0,0,0-6,3.46L.83,26.85a7.69,7.69,0,0,0,0,6.93l13.5,23.38a7.69,7.69,0,0,0,6,3.46h27a7.69,7.69,0,0,0,6-3.46l13.5-23.38a7.69,7.69,0,0,0,0-6.93Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape blue" data-sal="fade-in" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape blue" data-sal="fade-in" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape pink" data-sal="slide-up" data-sal-delay="300" xmlns="http://www.w3.org/2000/svg" width="68.57" height="65.75" viewBox="0 0 68.57 65.75">
        <path d="M37.52,1A5.88,5.88,0,0,0,31,1L2.23,21.91a5.88,5.88,0,0,0-2,6.16l11,33.88a5.88,5.88,0,0,0,5.24,3.8H52.1a5.88,5.88,0,0,0,5.24-3.8l11-33.88a5.88,5.88,0,0,0-2-6.16Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape pink" data-sal="slide-up" data-sal-delay="300" xmlns="http://www.w3.org/2000/svg" width="68.57" height="65.75" viewBox="0 0 68.57 65.75">
        <path d="M37.52,1A5.88,5.88,0,0,0,31,1L2.23,21.91a5.88,5.88,0,0,0-2,6.16l11,33.88a5.88,5.88,0,0,0,5.24,3.8H52.1a5.88,5.88,0,0,0,5.24-3.8l11-33.88a5.88,5.88,0,0,0-2-6.16Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape violet" data-sal="slide-up" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="67.65" height="60.62" viewBox="0 0 67.65 60.62">
        <path d="M53.33,3.46a7.69,7.69,0,0,0-6-3.46h-27a7.69,7.69,0,0,0-6,3.46L.83,26.85a7.69,7.69,0,0,0,0,6.93l13.5,23.38a7.69,7.69,0,0,0,6,3.46h27a7.69,7.69,0,0,0,6-3.46l13.5-23.38a7.69,7.69,0,0,0,0-6.93Z"></path>
      </svg>
      <svg class="shape green" data-sal="slide-up" data-sal-delay="300" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <path d="M38.94,2.34l9.52,17.41a4.49,4.49,0,0,0,1.79,1.79l17.41,9.52a4.49,4.49,0,0,1,0,7.89L50.25,48.47a4.49,4.49,0,0,0-1.79,1.79L38.94,67.66a4.49,4.49,0,0,1-7.89,0L21.53,50.25a4.49,4.49,0,0,0-1.79-1.79L2.34,38.94a4.49,4.49,0,0,1,0-7.89l17.41-9.52a4.49,4.49,0,0,0,1.79-1.79L31.06,2.34A4.49,4.49,0,0,1,38.94,2.34Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape violet" data-sal="slide-up" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="67.65" height="60.62" viewBox="0 0 67.65 60.62">
        <path d="M53.33,3.46a7.69,7.69,0,0,0-6-3.46h-27a7.69,7.69,0,0,0-6,3.46L.83,26.85a7.69,7.69,0,0,0,0,6.93l13.5,23.38a7.69,7.69,0,0,0,6,3.46h27a7.69,7.69,0,0,0,6-3.46l13.5-23.38a7.69,7.69,0,0,0,0-6.93Z"></path>
      </svg>
      <svg class="shape green" data-sal="slide-up" data-sal-delay="300" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <path d="M38.94,2.34l9.52,17.41a4.49,4.49,0,0,0,1.79,1.79l17.41,9.52a4.49,4.49,0,0,1,0,7.89L50.25,48.47a4.49,4.49,0,0,0-1.79,1.79L38.94,67.66a4.49,4.49,0,0,1-7.89,0L21.53,50.25a4.49,4.49,0,0,0-1.79-1.79L2.34,38.94a4.49,4.49,0,0,1,0-7.89l17.41-9.52a4.49,4.49,0,0,0,1.79-1.79L31.06,2.34A4.49,4.49,0,0,1,38.94,2.34Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape green" data-sal="zoom-in" data-sal-delay="300" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape green" data-sal="zoom-in" data-sal-delay="300" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape pink" data-sal="fade-in" data-sal-delay="250" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <circle cx="30" cy="30" r="30"></circle>
      </svg>
      <svg class="shape yellow" data-sal="fade-in" data-sal-delay="0" xmlns="http://www.w3.org/2000/svg" width="72.74" height="64.06" viewBox="0 0 72.74 64.06">
        <path d="M32.91,2a4,4,0,0,1,6.93,0L54,26.56l4,6.93L72.2,58.06a4,4,0,0,1-3.46,6H4a4,4,0,0,1-3.46-6L14.72,33.49l4-6.93Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape pink" data-sal="fade-in" data-sal-delay="250" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <circle cx="30" cy="30" r="30"></circle>
      </svg>
      <svg class="shape yellow" data-sal="fade-in" data-sal-delay="0" xmlns="http://www.w3.org/2000/svg" width="72.74" height="64.06" viewBox="0 0 72.74 64.06">
        <path d="M32.91,2a4,4,0,0,1,6.93,0L54,26.56l4,6.93L72.2,58.06a4,4,0,0,1-3.46,6H4a4,4,0,0,1-3.46-6L14.72,33.49l4-6.93Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape pink" data-sal="slide-up" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="69.11" height="69.11" viewBox="0 0 69.11 69.11">
        <rect x="8.94" y="8.94" width="51.22" height="51.22" rx="4" ry="4" transform="translate(-14.31 34.55) rotate(-45)"></rect>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape pink" data-sal="slide-up" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="69.11" height="69.11" viewBox="0 0 69.11 69.11">
        <rect x="8.94" y="8.94" width="51.22" height="51.22" rx="4" ry="4" transform="translate(-14.31 34.55) rotate(-45)"></rect>
      </svg>
    </div>
    <div class="column">
      <svg class="shape pink" data-sal="slide-up" data-sal-delay="250" xmlns="http://www.w3.org/2000/svg" width="69.11" height="69.11" viewBox="0 0 69.11 69.11">
        <rect x="8.94" y="8.94" width="51.22" height="51.22" rx="4" ry="4" transform="translate(-14.31 34.55) rotate(-45)"></rect>
      </svg>
      <svg class="shape blue" data-sal="zoom-in" data-sal-delay="0" xmlns="http://www.w3.org/2000/svg" width="68.57" height="65.75" viewBox="0 0 68.57 65.75">
        <path d="M37.52,1A5.88,5.88,0,0,0,31,1L2.23,21.91a5.88,5.88,0,0,0-2,6.16l11,33.88a5.88,5.88,0,0,0,5.24,3.8H52.1a5.88,5.88,0,0,0,5.24-3.8l11-33.88a5.88,5.88,0,0,0-2-6.16Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape pink" data-sal="slide-up" data-sal-delay="250" xmlns="http://www.w3.org/2000/svg" width="69.11" height="69.11" viewBox="0 0 69.11 69.11">
        <rect x="8.94" y="8.94" width="51.22" height="51.22" rx="4" ry="4" transform="translate(-14.31 34.55) rotate(-45)"></rect>
      </svg>
      <svg class="shape blue" data-sal="zoom-in" data-sal-delay="0" xmlns="http://www.w3.org/2000/svg" width="68.57" height="65.75" viewBox="0 0 68.57 65.75">
        <path d="M37.52,1A5.88,5.88,0,0,0,31,1L2.23,21.91a5.88,5.88,0,0,0-2,6.16l11,33.88a5.88,5.88,0,0,0,5.24,3.8H52.1a5.88,5.88,0,0,0,5.24-3.8l11-33.88a5.88,5.88,0,0,0-2-6.16Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape blue" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="72.74" height="64.06" viewBox="0 0 72.74 64.06">
        <path d="M32.91,2a4,4,0,0,1,6.93,0L54,26.56l4,6.93L72.2,58.06a4,4,0,0,1-3.46,6H4a4,4,0,0,1-3.46-6L14.72,33.49l4-6.93Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape blue" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="72.74" height="64.06" viewBox="0 0 72.74 64.06">
        <path d="M32.91,2a4,4,0,0,1,6.93,0L54,26.56l4,6.93L72.2,58.06a4,4,0,0,1-3.46,6H4a4,4,0,0,1-3.46-6L14.72,33.49l4-6.93Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape yellow" data-sal="fade-in" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <rect width="60" height="60" rx="4" ry="4"></rect>
      </svg>
      <svg class="shape yellow" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape yellow" data-sal="fade-in" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <rect width="60" height="60" rx="4" ry="4"></rect>
      </svg>
      <svg class="shape yellow" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape green" data-sal="slide-up" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <rect x="28" width="14" height="70" rx="7" ry="7"></rect>
        <rect x="28" width="14" height="70" rx="7" ry="7" transform="translate(0 70) rotate(-90)"></rect>
      </svg>
      <svg class="shape blue" data-sal="slide-up" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape green" data-sal="slide-up" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <rect x="28" width="14" height="70" rx="7" ry="7"></rect>
        <rect x="28" width="14" height="70" rx="7" ry="7" transform="translate(0 70) rotate(-90)"></rect>
      </svg>
      <svg class="shape blue" data-sal="slide-up" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape yellow" data-sal="slide-up" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="68.57" height="65.75" viewBox="0 0 68.57 65.75">
        <path d="M37.52,1A5.88,5.88,0,0,0,31,1L2.23,21.91a5.88,5.88,0,0,0-2,6.16l11,33.88a5.88,5.88,0,0,0,5.24,3.8H52.1a5.88,5.88,0,0,0,5.24-3.8l11-33.88a5.88,5.88,0,0,0-2-6.16Z"></path>
      </svg>
      <svg class="shape green" data-sal="zoom-in" data-sal-delay="50" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape yellow" data-sal="slide-up" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="68.57" height="65.75" viewBox="0 0 68.57 65.75">
        <path d="M37.52,1A5.88,5.88,0,0,0,31,1L2.23,21.91a5.88,5.88,0,0,0-2,6.16l11,33.88a5.88,5.88,0,0,0,5.24,3.8H52.1a5.88,5.88,0,0,0,5.24-3.8l11-33.88a5.88,5.88,0,0,0-2-6.16Z"></path>
      </svg>
      <svg class="shape green" data-sal="zoom-in" data-sal-delay="50" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape blue" data-sal="fade-in" data-sal-delay="50" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <rect x="28" width="14" height="70" rx="7" ry="7"></rect>
        <rect x="28" width="14" height="70" rx="7" ry="7" transform="translate(0 70) rotate(-90)"></rect>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape blue" data-sal="fade-in" data-sal-delay="50" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <rect x="28" width="14" height="70" rx="7" ry="7"></rect>
        <rect x="28" width="14" height="70" rx="7" ry="7" transform="translate(0 70) rotate(-90)"></rect>
      </svg>
    </div>
    <div class="column">
      <svg class="shape pink" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <rect x="28" width="14" height="70" rx="7" ry="7"></rect>
        <rect x="28" width="14" height="70" rx="7" ry="7" transform="translate(0 70) rotate(-90)"></rect>
      </svg>
      <svg class="shape pink" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape pink" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <rect x="28" width="14" height="70" rx="7" ry="7"></rect>
        <rect x="28" width="14" height="70" rx="7" ry="7" transform="translate(0 70) rotate(-90)"></rect>
      </svg>
      <svg class="shape pink" data-sal="zoom-in" data-sal-delay="150" xmlns="http://www.w3.org/2000/svg" width="70" height="67.18" viewBox="0 0 70 67.18">
        <path d="M40.55,3.45l6,12.21A6.18,6.18,0,0,0,51.23,19L64.7,21a6.18,6.18,0,0,1,3.43,10.55L58.38,41a6.18,6.18,0,0,0-1.78,5.47l2.3,13.42a6.18,6.18,0,0,1-9,6.52L37.88,60.12a6.18,6.18,0,0,0-5.76,0L20.07,66.46a6.18,6.18,0,0,1-9-6.52l2.3-13.42A6.18,6.18,0,0,0,11.62,41l-9.75-9.5A6.18,6.18,0,0,1,5.3,21l13.47-2a6.18,6.18,0,0,0,4.66-3.38l6-12.21A6.18,6.18,0,0,1,40.55,3.45Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape violet" data-sal="zoom-in" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <rect width="60" height="60" rx="4" ry="4"></rect>
      </svg>
      <svg class="shape blue" data-sal="fade-in" data-sal-delay="0" xmlns="http://www.w3.org/2000/svg" width="67.65" height="60.62" viewBox="0 0 67.65 60.62">
        <path d="M53.33,3.46a7.69,7.69,0,0,0-6-3.46h-27a7.69,7.69,0,0,0-6,3.46L.83,26.85a7.69,7.69,0,0,0,0,6.93l13.5,23.38a7.69,7.69,0,0,0,6,3.46h27a7.69,7.69,0,0,0,6-3.46l13.5-23.38a7.69,7.69,0,0,0,0-6.93Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape violet" data-sal="zoom-in" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <rect width="60" height="60" rx="4" ry="4"></rect>
      </svg>
      <svg class="shape blue" data-sal="fade-in" data-sal-delay="0" xmlns="http://www.w3.org/2000/svg" width="67.65" height="60.62" viewBox="0 0 67.65 60.62">
        <path d="M53.33,3.46a7.69,7.69,0,0,0-6-3.46h-27a7.69,7.69,0,0,0-6,3.46L.83,26.85a7.69,7.69,0,0,0,0,6.93l13.5,23.38a7.69,7.69,0,0,0,6,3.46h27a7.69,7.69,0,0,0,6-3.46l13.5-23.38a7.69,7.69,0,0,0,0-6.93Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape blue" data-sal="slide-up" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <rect width="60" height="60" rx="4" ry="4"></rect>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape blue" data-sal="slide-up" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <rect width="60" height="60" rx="4" ry="4"></rect>
      </svg>
    </div>
    <div class="column">
      <svg class="shape violet" data-sal="fade-in" data-sal-delay="300" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <path d="M38.94,2.34l9.52,17.41a4.49,4.49,0,0,0,1.79,1.79l17.41,9.52a4.49,4.49,0,0,1,0,7.89L50.25,48.47a4.49,4.49,0,0,0-1.79,1.79L38.94,67.66a4.49,4.49,0,0,1-7.89,0L21.53,50.25a4.49,4.49,0,0,0-1.79-1.79L2.34,38.94a4.49,4.49,0,0,1,0-7.89l17.41-9.52a4.49,4.49,0,0,0,1.79-1.79L31.06,2.34A4.49,4.49,0,0,1,38.94,2.34Z"></path>
      </svg>
      <svg class="shape blue" data-sal="slide-up" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="67.65" height="60.62" viewBox="0 0 67.65 60.62">
        <path d="M53.33,3.46a7.69,7.69,0,0,0-6-3.46h-27a7.69,7.69,0,0,0-6,3.46L.83,26.85a7.69,7.69,0,0,0,0,6.93l13.5,23.38a7.69,7.69,0,0,0,6,3.46h27a7.69,7.69,0,0,0,6-3.46l13.5-23.38a7.69,7.69,0,0,0,0-6.93Z"></path>
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <svg class="shape violet" data-sal="fade-in" data-sal-delay="300" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <path d="M38.94,2.34l9.52,17.41a4.49,4.49,0,0,0,1.79,1.79l17.41,9.52a4.49,4.49,0,0,1,0,7.89L50.25,48.47a4.49,4.49,0,0,0-1.79,1.79L38.94,67.66a4.49,4.49,0,0,1-7.89,0L21.53,50.25a4.49,4.49,0,0,0-1.79-1.79L2.34,38.94a4.49,4.49,0,0,1,0-7.89l17.41-9.52a4.49,4.49,0,0,0,1.79-1.79L31.06,2.34A4.49,4.49,0,0,1,38.94,2.34Z"></path>
      </svg>
      <svg class="shape blue" data-sal="slide-up" data-sal-delay="100" xmlns="http://www.w3.org/2000/svg" width="67.65" height="60.62" viewBox="0 0 67.65 60.62">
        <path d="M53.33,3.46a7.69,7.69,0,0,0-6-3.46h-27a7.69,7.69,0,0,0-6,3.46L.83,26.85a7.69,7.69,0,0,0,0,6.93l13.5,23.38a7.69,7.69,0,0,0,6,3.46h27a7.69,7.69,0,0,0,6-3.46l13.5-23.38a7.69,7.69,0,0,0,0-6.93Z"></path>
      </svg>
    </div>
    <div class="column">
      <svg class="shape green" data-sal="zoom-in" data-sal-delay="250" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
        <circle cx="30" cy="30" r="30"></circle>
      </svg>
      <svg class="shape blue" data-sal="zoom-in" data-sal-delay="200" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
        <rect x="28" width="14" height="70" rx="7" ry="7"></rect>
        <rect x="28" width="14" height="70" rx="7" ry="7" transform="translate(0 70) rotate(-90)"></rect>
      </svg>
    </div>
  </div>
</section>

</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { ArrowForwardOutline } from '@vicons/ionicons5'
import { useIsMobile } from '@/utils/composables'
import { useRouter } from 'vue-router'
import sal from "sal.js"

const isMobileRef = useIsMobile()

export default defineComponent({
  components: {
    ArrowForwardOutline,
  },
  setup() {
    const router = useRouter()
    onMounted(() => {
      sal({
        threshold: 0.2,
        once: false,
      })
    })
    return {
      isMobile: isMobileRef,
      router,
    }
  }
})
</script>

<style scoped>
:root {

  --blue: var(--success-color);
  --green: #6dffaf;
  --red: #fb163e;
  --violet: #c88eff;
  --yellow: #ffe16b;
  --pink: #ff7f9f;
}

.floating {
    animation-name: floating;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    /* margin-left: 30px; */
    /* margin-top: 5px; */
}

@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 20px); }
    100%   { transform: translate(0, -0px); }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

main {
  overflow: hidden;
  background-color: var(--secondary);
  padding-left: 16px;
  padding-right: 16px;
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 16px;
  background-color: var(--secondary);
}

.header__logo + .header__title {
  margin-left: 8px;
}

.header__title {
  font-size: 30px;
  margin: 0;
  margin-bottom: -6px;
  /* color: var(--text-secondary); */
}

.header__subtitle {
  display: none;
  /* color: var(--text-secondary); */
}

.header__subtitle+ .header__button {
  margin-left: auto;
}

.header__button {
  display: inline-block;
  height: 32px;
  padding: 0 16px;
  line-height: 34px;
  border-radius: 16px;
  /* color: #fff; */
  /* background-color: var(--violet); */
  text-decoration: none;
  transition: background-color 0.2s;
}

.header__button:hover {
  /* background-color: var(--accent); */
}

.content {
  padding-top: 112px;
  padding-bottom: 32px;
  /* background-color: #fff; */
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding: 0 16px;
  /* background-color: var(--secondary); */
  /* color: var(--text-secondary); */
}

.footer__link {
  /* color: var(--violet); */
  text-decoration: none;
  transition: color 0.2s;
}

.footer__link:hover {
  /* color: var(--accent); */
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 32px;
}

.row + .row {
  margin-top: 96px;
}

.column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 100%;
  max-width: 100%;
}

.column + .column {
  margin-top: 16px;
}

.row:nth-child(2n) .column {
  justify-content: space-around;
}

.shape.cross.pink {
  stroke: var(--pink);
}

.shape.cross.blue {
  stroke: var(--blue);
}

.shape.cross.green {
  stroke: var(--green);
}

.shape.cross.red {
  stroke: var(--red);
}

.shape.cross.violet {
  stroke: var(--violet);
}

.shape.cross.yellow {
  stroke: var(--yellow);
}

.shape.pink {
  fill: #006a44;
}

.shape.blue {
  fill: var(--blue);
}

.shape.green {
  fill: rgb(56, 137, 197);
}

.shape.red {
  fill: var(--red);
}

.shape.violet {
  fill: #c1272d;
}

.shape.yellow {
  fill: #fdb913;
}


  .header {
    top: 50%;
    right: auto;
    left: 50%;
    display: block;
    height: auto;
    transform: translate(-50%, -50%);
    text-align: center;
    /* background-color: transparent; */
  }

  .header__logo {
    width: 124px;
    height: 70px;
  }

  .header__logo + .header__title {
    margin-top: 16px;
    margin-left: 0;
  }

  .header__title {
    margin: 0;
  }

  .header__title + .header__subtitle {
    margin: 8px 0 0;
  }

  .header__subtitle {
    display: block;
  }

  .header__subtitle + .header__button {
    margin-top: 32px;
  }

  .content {
    padding-top: 32px;
  }

  .column {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .column:first-of-type {
    padding-right: 144px;
  }

  .column + .column {
    margin-top: 0;
    padding-left: 144px;
  }


  /* https://www.florin-pop.com/blog/2019/03/css-pulse-effect/ */
  .blob {
    cursor: pointer;
    background: black;
    border-radius: 200%;
    margin: 70px;
    height: 70px;
    width: 70px;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(2);
    animation: pulse 2s infinite;

    transition: 1s;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      transform: scale(1.1);
      box-shadow: 0 0 0 40px rgba(0, 0, 0, 0);
    }

    100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  .blob:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .fade-in-text {
    animation: fadeIn linear 1s;
    -webkit-animation: fadeIn linear 1s;
    -moz-animation: fadeIn linear 1s;
    -o-animation: fadeIn linear 1s;
    -ms-animation: fadeIn linear 1s;
  }

  .wave {
    animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s;        /* Change to speed up or slow down */
    animation-iteration-count: infinite;  /* Never stop waving :) */
    transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
    display: inline-block;
  }

  @keyframes wave-animation {
      0% { transform: rotate( 0.0deg) }
      5% { transform: rotate( 0.0deg) }
     10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
     20% { transform: rotate(-8.0deg) }
     30% { transform: rotate(14.0deg) }
     40% { transform: rotate(-4.0deg) }
     50% { transform: rotate(10.0deg) }
     60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
    100% { transform: rotate( 0.0deg) }
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }

</style>
